import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sidebar"];

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";
  }

  toggleSidebar(event) {
    event.preventDefault();

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass);
    });
  }
}

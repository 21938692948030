import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["query", "stops"];
  connect() {
    console.log("Stops Load");
  }

  submit() {
    const value = this.queryTarget.value;
    fetch(`/?query=${value}`, {
      headers: { accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        var stopHTML = "";
        var stopArray = Object.values(data)[0];
        stopArray.forEach((stop) => {
          stopHTML += this.stopTemplate(stop);
        });
        this.stopsTarget.innerHTML = stopHTML;
      });
  }

  stopTemplate(stop) {
    return `
    <div class="grid grid-cols-4">
            <div>Load #${stop.load_id}</div>
            <div>${stop.address}</div>
            <div>${stop.city} ${stop.state}</div>
            <div class="capitalize">${stop.ship_type}</div>
        </div>
    `;
  }
}

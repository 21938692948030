import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox", "updateButtons"];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return;

    this.checkboxAllTarget.addEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.addEventListener("change", this.refresh)
    );
    this.refresh();
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return;

    this.checkboxAllTarget.removeEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.removeEventListener("change", this.refresh)
    );
  }

  toggle(e) {
    e.preventDefault();

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });

    // this.updateButtonsTarget.classList.toggle("hidden");
  }

  markDelivered(event) {
    // Clicking button does not refresh
    event.preventDefault();

    let data = new FormData();
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value));

    Rails.ajax({
      url: "/loads/bulk/mark_delivered",
      type: "PATCH",
      data: data,
    });
  }
  markBooked(event) {
    // Clicking button does not refresh
    event.preventDefault();

    let data = new FormData();
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value));

    Rails.ajax({
      url: "/loads/bulk/mark_booked",
      type: "PATCH",
      data: data,
    });
  }
  markCancelled(event) {
    // Clicking button does not refresh
    event.preventDefault();

    let data = new FormData();
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value));

    Rails.ajax({
      url: "/loads/bulk/mark_cancelled",
      type: "PATCH",
      data: data,
    });
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["map", "field_sw_lat", "field_sw_lng", "field_ne_lat", "field_ne_lng"]

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
          }
    }

    initMap() {
        const sw_lat = this.data.get("field_sw_lat") || 37.690439
        const sw_lng = this.data.get("field_sw_lng") || -121.23901
        const ne_lat = this.data.get("field_ne_lat") || 37.798917
        const ne_lng = this.data.get("field_ne_lng") || -121.00761

        const center_lat = (sw_lat + ne_lat)/2 
        const center_lng = (sw_lng + ne_lng)/2

        this.map = new google.maps.Map(this.mapTarget, {
            center: new google.maps.LatLng(center_lat, center_lng),
            zoom: 10
        })

        const original_bounds = {
            north: ne_lat,
            south: sw_lat,
            east: ne_lng,
            west: sw_lng,
        };
          // Define a rectangle and set its editable property to true.
        this.rectangle = new google.maps.Rectangle({
            bounds: original_bounds,
            editable: true,
            draggable: true,
        });
        this.rectangle.setMap(this.map);
        // Add an event listener on the rectangle.
        this.rectangle.addListener("bounds_changed", this.getUpdatedBounds.bind(this));
        

    }

    /** Show the new coordinates for the rectangle in an info window. */
    getUpdatedBounds() {
       let rectangle = this.rectangle
       const ne = rectangle.getBounds().getNorthEast();
       const sw = rectangle.getBounds().getSouthWest();
       const contentString =
       "New north-east corner: " +
       ne.lat() + " " +
       ne.lng() + " " +
       "New south-west corner: " +
       sw.lat() + " " +
       sw.lng(); 

       //console.log(contentString)

       this.field_sw_latTarget.value = sw.lat()
       this.field_sw_lngTarget.value = sw.lng()
       this.field_ne_latTarget.value = ne.lat()
       this.field_ne_lngTarget.value = ne.lng()

    }

}
import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["label", "pickup", "delivery"]

    connect() {
        console.log("Stop Type Form working")
    }

    onSelectStopType(event) {
        const stop_type = event.target.value;

        if (stop_type == "shipper") {
            console.log("pick up date");
            this.pickupTarget.classList.toggle('hidden');
            this.deliveryTarget.classList.toggle('hidden');
        } else if (stop_type == "consignee") {

            console.log("delivery date");
            this.pickupTarget.classList.toggle('hidden');
            this.deliveryTarget.classList.toggle('hidden');

        }

    }

}
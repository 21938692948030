// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import { Alert, Dropdown, Modal, Tabs } from "tailwindcss-stimulus-components";
application.register("alert", Alert);
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);

import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);

// import CheckboxSelectAll from "stimulus-checkbox-select-all";
// application.register("checkbox-select-all", CheckboxSelectAll);

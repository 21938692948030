import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["query", "loads"];
  connect() {
    //console.log("hello from stimulus");
  }

  submit() {
    const value = this.queryTarget.value;
    fetch(`/?query=${value}`, {
      headers: { accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        var loadHTML = "";
        var loadArray = Object.values(data)[0];
        loadArray.forEach((load) => {
          loadHTML += this.loadTemplate(load);
        });
        this.loadsTarget.innerHTML = loadHTML;
      });
  }

  loadTemplate(load) {
    return `
    <div class="grid grid-cols-4">
            <div>Load #${load.id}</div>
            <div>${load.date_received}</div>
            <div>Total Revenue ${load.total_revenue}</div>
            <div class="text-purple-500">${load.status_wording}</div>
        </div>
    `;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkboxContainer", "checkbox", "toggleable"];

  handleChangeCheckbox(ev) {
    //console.log(this.checkboxTarget.checked);
    //console.log(ev.target.checked);

    this.toggleableTargets.forEach((target) => {
      if (this.checkboxTarget.checked) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["buttons", "notes"];

  initialize() {
    this.fillForm = this.fillForm.bind(this);
  }

  connect() {
    this.getTemplates();
    console.log(this.notesTarget.innerHTML);
  }

  getTemplates() {
    // console.log("getting the templates!");
    //  Rails.ajax({
    //  url: "/loads/bulk/mark_delivered",
    //  type: "PATCH",
    //  data: data,
    //  });
    let templates = [
      {
        id: 1,
        title: "Tony",
        body: "Call Tony with any problems @209.456.0789",
      },
      {
        id: 2,
        title: "Joe",
        body: "Call Joe with any problems @209.613.6865",
      },
      {
        id: 3,
        title: "Leanna",
        body: "Call Leanna with any problems @209.608.1994",
      },
      {
        id: 4,
        title: "Chance",
        body: "Call Chance with any problems @209.857.9605",
      },
    ];
    templates.forEach((temp) => {
      const btn = document.createElement("div");
      btn.innerHTML = temp.title;
      btn.setAttribute(
        "class",
        "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer ml-1"
      );
      //btn.setAttribute(
      //  "data-action",
      //  `click->fill-form#fillForm(${temp.body}))`
      //);
      btn.onclick = function () {
        document.getElementById("load_notes").value = temp.body;
      };
      this.buttonsTarget.appendChild(btn);
    });
  }

  fillForm(body) {
    this.notesTarget.value = body;
  }
}

import { Controller } from "stimulus";
import axios from "axios";
import autocomplete from "autocomplete.js";

export default class extends Controller {
  static targets = [
    "field",
    "suggestionTemplate",
    "hiddenField",
    "showDetails",
  ];

  source() {
    const url = this.data.get("url");
    return (query, callback) => {
      axios.get(url, { params: { query } }).then((response) => {
        callback(response.data);
      });
    };
  }

  connect() {
    this.ac = autocomplete(
      this.fieldTarget,
      {
        autoselect: true,
        hint: false,
      },
      [
        {
          source: this.source(),
          debounce: 200,
          templates: {
            empty:
              '<p class="py-1 px-1">No Locations Found. Please try again.</p>',
            suggestion: function (suggestion) {
              return suggestion.name;
              //return this.showData(suggestion)
            },
          },
        },
      ]
    ).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion.name);
      this.insertData(suggestion.id);
      this.insertDetails(suggestion.id);
    });
  }

  //insert the data into the hidden field
  insertData(data) {
    this.hiddenFieldTarget.value = data;
  }

  insertDetails(data) {
    const record_id = data;
    Rails.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: `/locations/${record_id}.json`,
      dataType: "json",
      success: (e) => {
        this.showData(e);
      },
    });
  }
  //insert the data into the DOM
  showData(data) {
    //console.log(data)
    var modelHTML = "";
    modelHTML += this.modelTemplate(data);
    this.showDetailsTarget.innerHTML = modelHTML;
  }

  modelTemplate(model) {
    return `
        <h5 class="mb-3">Location Details</h5>

        <div class="flex flex-col space-y-1 text-sm">
            <div>${model.street}</div>
            <div> ${model.city} ${model.state} ${model.zip}</div>
            <div>${model.contact_name}</div>
            <div>${model.phone}</div>
            <div>${model.contact_email}</div>
            <div>${model.contact_phone}</div>
            <div><a href="/locations/${model.id}" target="_blank">Edit Location Details</a></div>
        </div>

    `;
  }
}

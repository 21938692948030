import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "dropdown", "select"];
  connect() {}

  // handle placeholder changes
  queryChange(event) {
    event.preventDefault();
    if (this.selectTarget.value == "id") {
      this.inputTarget.placeholder = "Search Loads by ID";
      this.inputTarget.name = "load_id";
    } else if (this.selectTarget.value == "location") {
      this.inputTarget.placeholder = "Search Loads by Location";
      this.inputTarget.name = "query";
    } else if (this.selectTarget.value == "details") {
      this.inputTarget.placeholder = "Search Loads by Details";
      this.inputTarget.name = "details";
    } else {
      this.inputTarget.placeholder = "Search Loads";
      this.inputTarget.name = "query";
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.selectTarget.value == "id") {
      this.inputTarget.name = "load_id";
    } else if (this.selectTarget.value == "location") {
      this.inputTarget.name = "query";
    } else if (this.selectTarget.value == "details") {
      this.inputTarget.name = "query";
    } else {
      this.inputTarget.name = "query";
    }
  }

  // Click outside of event
  hide(event) {
    if (
      event &&
      (this.dropdownTarget.contains(event.target) ||
        this.inputTarget.contains(event.target))
    ) {
      return;
    }

    // this.buttonTarget.setAttribute('aria-expanded', 'false');
    // this.buttonTarget.classList.remove('is-active');
    this.dropdownTarget.classList.toggle("hidden");
  }

  get query() {
    return this.inputTarget.value;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["area", "entries", "pagination"];

  initialize() {
    this.intersectionObserver = new IntersectionObserver((entries) =>
      this.processIntersectionEntries(entries)
    );
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  //Older scroll code
  scroll() {
    let url = this.paginationTarget.querySelector("a[rel='next']").href;

    var height = Math.max(
      this.areaTarget.scrollHeight,
      this.areaTarget.offsetHeight,
      this.areaTarget.clientHeight
    );

    if (this.areaTarget.scrollTop >= height - window.innerHeight) {
      this.loadMore(url); // no longer valid as url is not a param
    }
  }

  loadMore() {
    // prevent duplicate loading if scrolling too fast
    if (this.loading) {
      return;
    }

    this.loading = true;

    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (next_page == null) {
      return;
    }
    let url = next_page.href;

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        console.log(data);
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.loading = false;
      },
    });
  }
}

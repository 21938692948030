import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["location"];
  connect() {
    //console.log("Hello from Stimulus JS");
  }

  onSelectLocation(event) {
    const location_id = event.target.value;
    //console.log(location_id);

    Rails.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: `/locations/${location_id}.json`,
      dataType: "json",
      success: (data) => {
        this.showData(data);
      },
    });
  }

  //insert the data into the DOM
  showData(data) {
    //console.log(data)
    var locationHTML = "";
    locationHTML += this.locationTemplate(data);
    this.locationTarget.innerHTML = locationHTML;
  }

  locationTemplate(location) {
    return `
        <h5 class="mb-3">Location Details</h5>

        <div class="flex flex-col space-y-1 text-sm">
            <div>${location.street}</div>
            <div> ${location.city} ${location.state} ${location.zip}</div>
            <div>${location.contact_name}</div>
            <div>${location.phone}</div>
            <div>${location.contact_email}</div>
            <div>${location.contact_phone}</div>
            <div><a href="/locations/${location.id}" target="_blank">Edit Location Details</a></div>
        </div>

    `;
  }
}

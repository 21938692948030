import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["first", "second"];

  // Toggle the hidden class on the dropdown
  toggleFirst() {
    console.log("CLICK");
  }

  toggleSecond() {}
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["carrier"];
  connect() {
    console.log("Hello from Stimulus JS");
  }

  onSelectCarrier(event) {
    const carrier_id = event.target.value;
    //console.log(carrier_id);

    Rails.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: `/carriers/${carrier_id}.json`,
      dataType: "json",
      success: (data) => {
        this.showData(data);
      },
    });
  }

  showData(data) {
    //console.log(data);
    var carrierHTML = "";
    carrierHTML += this.carrierTemplate(data);
    this.carrierTarget.innerHTML = carrierHTML;
  }

  carrierTemplate(carrier) {
    return `
        <h5 class="mb-3">carrier Details</h5>

        <div class="flex flex-col space-y-1 text-sm">
            <div>${carrier.street}</div>
            <div> ${carrier.city} ${carrier.state} ${carrier.zip}</div>
            <div>${carrier.contact_name}</div>
            <div>${carrier.phone}</div>
            <div>${carrier.contact_email}</div>
            <div>${carrier.contact_phone}</div>
            <div><a href="/carriers/${carrier.id}" target="_blank">Edit Carrier Details</a></div>
        </div>

    `;
  }
}
